import React, { Component } from 'react'

class Email extends Component {
  constructor (props) {
    super(props)
    this.isLink = false
    this.mailTo = ''
    this.emailCountdown = 2
    this.emailMessage = '[reveals in ' + this.emailCountdown + 's]'
  }

  componentDidMount() {
    this.countdown = setInterval(
      () => {
        this.updateCountdown()
      }, 1000
    );

    this.timeout = setTimeout(
      () => {
        this.updateEmail()
      }, 2000
    );
  }

  componentWillUnmount() {
    clearInterval(this.countdown)
    clearTimeout(this.timeout)
  }

  updateCountdown() {
    this.emailCountdown -= 1
    this.emailMessage = '[reveals in ' + this.emailCountdown + 's]'
    this.setState({
      emailMessage: this.emailMessage
    })
  }

  updateEmail() {
    clearInterval(this.countdown)
    const addressBackwards = 'moc.diawnilloc@erih'
    const addressForwards = addressBackwards.split('').reverse().join('')
    this.mailTo = 'mailto:' + addressForwards
    this.emailMessage = addressForwards
    this.isLink = true

    this.setState({
      mailTo: this.mailTo,
      emailMessage: this.emailMessage,
      isLink: this.isLink
    })
  }

  render() {
    if (this.isLink) {
      return (
        <a href={this.mailTo} rel='nofollow'>
          <span className='EmailObfuscation'>REMOVE</span>
          {this.emailMessage}
        </a>
      )
    }
    return <span>{this.emailMessage}</span>
  }
}

export default Email
