import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Email from '../components/email'
import resumePDF from '../files/Collin Waid Resume.pdf'

import { InlineIcon } from '@iconify/react'
import mailTwotone from '@iconify/icons-ant-design/mail-twotone'
import linkedinFilled from '@iconify/icons-ant-design/linkedin-filled'
import downloadOutlined from '@iconify/icons-ant-design/download-outlined'
import githubFilled from '@iconify/icons-ant-design/github-filled';

const ContactPage = () => (
  <Layout>
    <div className='PageHeader'>
      <h1>Contact</h1>
    </div>
    <div className='constrict-content'>
      {/* <p>
        I’m currently <span className='WorkStatus'>available</span> for new career opportunities.
      </p>
      <p>
        If my <Link to='/portfolio/'>portfolio</Link> peeked your interest, please feel free to reach out.
        <br/>
        A PDF version of my resume is available for <a className='DownloadResume__link' href={resumePDF} rel='nofollow' download='Collin Waid Resume'><InlineIcon icon={downloadOutlined} /> download</a>.
      </p> */}
      <ul className='ContactList'>
        <li>
          <span className='ContactList__label'>
            <InlineIcon className='ContactList__icon' icon={mailTwotone} />
            Email:
          </span>
          <span className='ContactList__info ContactList__info--email'>
            <Email />
          </span>
        </li>
        <li>
          <span className='ContactList__label'>
            <InlineIcon className='ContactList__icon' icon={linkedinFilled} />
            LinkedIn:
          </span>
          <span className='ContactList__info ContactList__info--linkedin'>
            <OutboundLink href='https://www.linkedin.com/in/collinw/' target='_blank' rel='noopener noreferrer'>
              linkedin.com/in/collinw
            </OutboundLink>
          </span>
        </li>
        <li>
          <span className='ContactList__label'>
            <InlineIcon className='ContactList__icon' icon={githubFilled} />
            GitHub:
          </span>
          <span className='ContactList__info ContactList__info--linkedin'>
            <OutboundLink href='https://github.com/developer-collin' target='_blank' rel='noopener noreferrer'>
              github.com/developer-collin
            </OutboundLink>
          </span>
        </li>
      </ul>
    </div>
  </Layout>
)

export default ContactPage
